import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { DataGrid } from "@material-ui/data-grid";

import { userActions } from "../../_actions";

class CronJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_jobs: [],
      selected_statuses: [],
      submitted: false
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.cronEnable = this.cronEnable.bind(this);
    this.cronDisable = this.cronDisable.bind(this);
    this.handleToggleSelectedJobs = this.handleToggleSelectedJobs.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.cronjobsGet());
  }

  handleSelectionChange(selections) {
    let rowIds = selections.rowIds;
    this.setState({ selected_jobs: rowIds });

    var statuses = [];
    this.props.cronjobs.jobs.forEach(function (job) {
      if (rowIds.includes(job.Name)) {
        statuses.push(job.State);
      }
    });
    this.setState({ selected_statuses: statuses });
  }

  handleRefresh(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(userActions.cronjobsGet());
  }

  handleToggleSelectedJobs() {
    let selected_jobs = this.state.selected_jobs;
    let selected_statuses = this.state.selected_statuses;
    if (selected_jobs.length > 0) {
      let cron_name = selected_jobs[0];
      if (selected_statuses[0] == "ENABLED") {
        this.cronDisable(cron_name);
      } else {
        this.cronEnable(cron_name);
      }
    }
    this.setState({ selected_jobs: [] });
    this.setState({ selected_statuses: [] });
  }

  cronEnable(cron_name) {
    const { dispatch } = this.props;
    dispatch(userActions.cronjobsEnable(cron_name));
  }

  cronDisable(cron_name) {
    const { dispatch } = this.props;
    dispatch(userActions.cronjobsDisable(cron_name));
  }

  render() {
    const { authentication, alert, zylocust, mocklms, cronjobs } = this.props;
    const { submitted } = this.state;

    const noPointer = { cursor: "pointer" };

    const columns = [
      { field: "Name", headerName: "name", width: 400 },
      {
        field: "ScheduleExpression",
        headerName: "schedule expression",
        width: 300
      },
      { field: "State", headerName: "state", width: 100 }
    ];

    const rows = cronjobs.jobs ? cronjobs.jobs : [];
    const count = rows.length;

    return (
      <Container component="main" xs={12} sm={12} md={12}>
        <CssBaseline />

        {alert.message && (
          <Alert
            fullWidth={true}
            width={"100%"}
            severity={
              alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"
            }
          >
            <AlertTitle>
              {alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"}
            </AlertTitle>
            {alert.message}
          </Alert>
        )}

        <GridContainer display="flex" alignItems="center">
          <GridItem>Cron Jobs ({count})</GridItem>
          <GridItem>
            <Button
              variant="contained"
              color={
                this.state.selected_statuses[0] == "ENABLED"
                  ? "secondary"
                  : "primary"
              }
              disabled={
                this.state.selected_jobs.length == 0 || cronjobs.loading
              }
              onClick={this.handleToggleSelectedJobs}
            >
              {this.state.selected_statuses[0] == "ENABLED"
                ? "Disable"
                : "Enable"}{" "}
              Selected
            </Button>
          </GridItem>
        </GridContainer>

        <br />

        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            onSelectionChange={this.handleSelectionChange}
          />
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert, zylocust, mocklms, cronjobs } = state;
  return {
    authentication,
    alert,
    zylocust,
    mocklms,
    cronjobs
  };
}

const connectedCronJobs = connect(mapStateToProps)(CronJobs);
export { connectedCronJobs as default };
