import React from "react";
import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { registrationActions } from "../../_actions";
import logo from "assets/img/zylocust/flies.png";

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      given_name: "",
      family_name: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, given_name, family_name, password } = this.state;
    const { dispatch } = this.props;
    if (email && given_name && family_name && password) {
      dispatch(
        registrationActions.signup(email, given_name, family_name, password)
      );
    }
  }

  render() {
    const { registration, alert } = this.props;
    const { email, password, given_name, family_name, submitted } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography component="h3" variant="h3">
            zyLocust
          </Typography>
          <img src={logo} alt="Logo" height={150} />
          <Typography component="p" variant="body2">
            loadtesting at biblical proportions
          </Typography>
          <br />
          {alert.message && (
            <Alert
              fullWidth={true}
              width={"100%"}
              severity={
                alert.type == "alert-success"
                  ? "success"
                  : alert.type == "alert-danger"
                  ? "error"
                  : "info"
              }
            >
              <AlertTitle>
                {alert.type == "alert-success"
                  ? "success"
                  : alert.type == "alert-danger"
                  ? "error"
                  : "info"}
              </AlertTitle>
              {alert.message}
            </Alert>
          )}
          <br />
        </Box>

        <Typography component="h5" variant="h5">
          Sign Up
        </Typography>
        <Typography component="p" variant="body2">
          If you are a first time sandbox user, you need to verify your email
          address via the AWS console. See zyLocust README for instructions.
          Without verification, you will be unable to complete the registration
          process.
        </Typography>
        <form name="form" onSubmit={this.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="given_name"
            label="first name"
            name="given_name"
            value={given_name}
            onChange={this.handleChange}
            disabled={registration.loading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="family_name"
            label="last name"
            name="family_name"
            value={family_name}
            onChange={this.handleChange}
            disabled={registration.loading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            autoComplete="email"
            disabled={registration.loading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            onChange={this.handleChange}
            label="password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={registration.loading}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={registration.loading}
          >
            Sign Up
          </Button>

          <Grid container>
            <Grid item xs>
              <Button component={Link} to="/signin">
                {"Already have an account? Sign In"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { registration, alert } = state;
  return {
    registration,
    alert
  };
}

const connectedSignUp = connect(mapStateToProps)(SignUp);
export { connectedSignUp as default };
