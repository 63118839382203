import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { registrationActions } from "../../_actions";
import logo from "assets/img/zylocust/death.png";

class Password extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    if (email) {
      dispatch(registrationActions.passwordForgot(email));
    }
  }

  render() {
    const { registration, alert } = this.props;
    const { email, submitted } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography component="h3" variant="h3">
            zyLocust
          </Typography>
          <img src={logo} alt="Logo" height={150} />
          <Typography component="p" variant="body2">
            loadtesting at biblical proportions
          </Typography>
          <br />
          {alert.message && (
            <Alert
              fullWidth={true}
              width={"100%"}
              severity={
                alert.type == "alert-success"
                  ? "success"
                  : alert.type == "alert-danger"
                  ? "error"
                  : "info"
              }
            >
              <AlertTitle>
                {alert.type == "alert-success"
                  ? "success"
                  : alert.type == "alert-danger"
                  ? "error"
                  : "info"}
              </AlertTitle>
              {alert.message}
            </Alert>
          )}
          <br />
        </Box>

        <Typography component="h5" variant="h5">
          Forgot Password?
        </Typography>
        <Typography component="p" variant="body2">
          Enter your email to reset your password.
        </Typography>

        <form name="form" onSubmit={this.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            disabled={registration.loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={registration.loading}
          >
            Reset Password
          </Button>

          <Grid container>
            <Grid item xs>
              <Button component={Link} to="/signin">
                {"Already have an account? Sign In"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { registration, alert } = state;
  return {
    registration,
    alert
  };
}

const connectedPassword = connect(mapStateToProps)(Password);
export { connectedPassword as default };
