import { registrationConstants } from "../_constants";
let user = JSON.parse(localStorage.getItem("reg"));
const initialState = user ? { loading: false, user } : {};

export function registration(state = initialState, action) {
  switch (action.type) {
    case registrationConstants.PASSWORD_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case registrationConstants.PASSWORD_SUCCESS:
      return {
        user: action.user
      };
    case registrationConstants.PASSWORD_FAILURE:
      return {
        ...state,
        loading: false
      };

    case registrationConstants.PASSWORD_RESET_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case registrationConstants.PASSWORD_RESET_SUCCESS:
      return {
        user: action.user
      };
    case registrationConstants.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        loading: false
      };

    case registrationConstants.SIGNUP_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case registrationConstants.SIGNUP_SUCCESS:
      return {
        user: action.user
      };
    case registrationConstants.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false
      };

    case registrationConstants.SIGNUP_RESEND_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case registrationConstants.SIGNUP_RESEND_SUCCESS:
      return {
        user: action.user
      };
    case registrationConstants.SIGNUP_RESEND_FAILURE:
      return {
        ...state,
        loading: false
      };

    case registrationConstants.SIGNUP_CONFIRM_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case registrationConstants.SIGNUP_CONFIRM_SUCCESS:
      return {
        user: action.user
      };
    case registrationConstants.SIGNUP_CONFIRM_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
