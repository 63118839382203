import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Face from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";

import { userActions } from "../../_actions";
import logo from "assets/img/zylocust/lice.png";
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import dropdownStyle from "assets/jss/material-dashboard-react/dropdownStyle.js";

const useStyles = (theme) => ({
  ...dropdownStyle(theme),
  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    display: "inline-block"
  }
});

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null
    };

    this.handleToggleProfile = this.handleToggleProfile.bind(this);
    this.handleOpenProfile = this.handleOpenProfile.bind(this);
    this.handleCloseProfile = this.handleCloseProfile.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleOpenProfile(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  handleCloseProfile(e) {
    e.preventDefault();
    this.setState({ open: false });
  }

  handleToggleProfile(e) {
    e.preventDefault();
    this.setState({ anchorEl: e.currentTarget });
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  handleLogout(e) {
    e.preventDefault();
    const username = this.props.authentication.user.username;
    this.props.dispatch(userActions.logout(username));
  }

  render() {
    const { authentication, alert } = this.props;
    const user = authentication.user ? authentication.user : {};
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.manager}>
          <Button
            type="button"
            onClick={this.handleToggleProfile}
            className={classes.buttonLink}
          >
            <Face className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>

          <Poppers
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem>
                        {user.given_name}&nbsp;{user.family_name}
                      </MenuItem>
                      <MenuItem>{user.email}</MenuItem>
                      <Divider light />
                      <MenuItem
                        component={Link}
                        to={"/logout"}
                        onClick={this.handleLogout}
                      >
                        Logout&nbsp;
                        <ExitToApp />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert } = state;
  return {
    authentication,
    alert
  };
}

const connectedAdminNavbar = connect(mapStateToProps)(AdminNavbar);

export default withStyles(useStyles)(connectedAdminNavbar);
//export default withStyles(styles)(connectedAdminNavbar)
