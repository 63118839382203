import { userConstants } from "../_constants";

export function cronjobs(state = {}, action) {
  switch (action.type) {
    case userConstants.CRONJOBS_GET_REQUEST:
      return {
        loading: true
      };
    case userConstants.CRONJOBS_GET_SUCCESS:
      return {
        jobs: action.data
      };
    case userConstants.CRONJOBS_GET_FAILURE:
      return {
        error: action.error
      };

    case userConstants.CRONJOBS_POST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CRONJOBS_POST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.CRONJOBS_POST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.CRONJOBS_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CRONJOBS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.CRONJOBS_DELETE_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
