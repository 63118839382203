//import config from 'config';
import { authHeader } from "../_helpers";
import configData from "../config.json";

export const registrationService = {
  passwordForgot,
  passwordReset,
  signup,
  signupResend,
  signupConfirm
};

function passwordForgot(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  };
  return fetch(`${configData.authUrl}/password/forgot`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("reg", JSON.stringify(user));
      return user;
    });
}

function passwordReset(email, password, code) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, code })
  };
  return fetch(
    `${configData.authUrl}/password/forgot/confirm`,
    requestOptions
  ).then(handleResponse);
}

function signup(email, given_name, family_name, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, given_name, family_name, password })
  };
  return fetch(`${configData.authUrl}/signup`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("reguser", JSON.stringify(user));
      return user;
    });
}

function signupResend(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  };
  return fetch(`${configData.authUrl}/signup/resend`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("reguser", JSON.stringify(user));
      return user;
    });
}

function signupConfirm(email, code) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, code })
  };
  return fetch(`${configData.authUrl}/signup/confirm`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 400) {
        // auto logout if 400 response returned from api
        //logout();
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
