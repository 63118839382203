//import config from 'config';
import { authHeader } from "../_helpers";
import configData from "../config.json";

export const userService = {
  login,
  logout,
  getServices,
  addMasterService,
  addWorkerService,
  removeService,
  removeAllServices,
  mocklmsGet,
  mocklmsCreate,
  mocklmsDelete,
  cronjobsGet,
  cronjobsEnable,
  cronjobsDisable
};

// Authentication

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${configData.authUrl}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

function logout(username = "") {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ username })
  };
  return fetch(`${configData.authUrl}/logout`, requestOptions)
    .then(handleResponse)
    .then((message) => {
      // delete user details
      localStorage.removeItem("user");
      return message;
    });
}

// zyLocust Services

function getServices() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/service`, requestOptions).then(
    handleResponse
  );
}

function addMasterService(task_definition, group_id) {
  let headless = false;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ headless })
  };
  return fetch(
    `${configData.apiUrl}/service/${task_definition}/${group_id}`,
    requestOptions
  ).then(handleResponse);
}

function addWorkerService(
  task_definition,
  group_id,
  master_service_name,
  desired_count
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ master_service_name, desired_count })
  };
  return fetch(
    `${configData.apiUrl}/service/${task_definition}/${group_id}`,
    requestOptions
  ).then(handleResponse);
}

function removeService(task_definition, group_id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(
    `${configData.apiUrl}/service/${task_definition}/${group_id}`,
    requestOptions
  ).then(handleResponse);
}

function removeAllServices() {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/service`, requestOptions).then(
    handleResponse
  );
}

// Mock LMS

function mocklmsGet() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/mocklms/admin`, requestOptions).then(
    handleResponse
  );
}

function mocklmsCreate(message) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ message })
  };
  return fetch(`${configData.apiUrl}/mocklms`, requestOptions).then(
    handleResponse
  );
}

function mocklmsDelete() {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/mocklms/admin`, requestOptions).then(
    handleResponse
  );
}

// Cron Jobs

function cronjobsGet() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/cron`, requestOptions).then(
    handleResponse
  );
}

function cronjobsEnable(cron_name) {
  const requestOptions = {
    method: "POST",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/cron/${cron_name}`, requestOptions).then(
    handleResponse
  );
}

function cronjobsDisable(cron_name) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(`${configData.apiUrl}/cron/${cron_name}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      console.log("!response.ok");

      if (response.status === 400) {
        // auto logout if 400 response returned from api
        logout();
        //location.reload(true);
      }
      if (response.status === 401) {
        // auto logout if 400 response returned from api
        console.log("401");
        logout();
        //location.reload(true);
      }
      if (response.status === 403) {
        // auto logout if 403 response returned from api
        console.log("403");
        logout();
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
