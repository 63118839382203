import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const userActions = {
  login,
  logout,
  getServices,
  addMasterService,
  addWorkerService,
  removeService,
  removeAllServices,
  mocklmsGet,
  mocklmsCreate,
  mocklmsDelete,
  cronjobsGet,
  cronjobsEnable,
  cronjobsDisable
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        history.push("/admin");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        history.push("/");
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout(username) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.logout(username).then(
      (user) => {
        dispatch(success());
        history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        history.push("/");
      }
    );
  };

  function request() {
    return { type: userConstants.LOGOUT_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGOUT_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE, error };
  }
}

function getServices() {
  return (dispatch) => {
    dispatch(request());

    userService.getServices().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GETALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function addMasterService(task_definition, group_id) {
  return (dispatch) => {
    dispatch(request());

    userService.addMasterService(task_definition, group_id).then(
      (data) => {
        dispatch(success(data));
        dispatch(getServices());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(getServices());
      }
    );
  };

  function request() {
    return { type: userConstants.ADD_SERVICE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ADD_SERVICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ADD_SERVICE_FAILURE, error };
  }
}

function addWorkerService(
  task_definition,
  group_id,
  master_service_name,
  desired_count
) {
  return (dispatch) => {
    dispatch(request());

    userService
      .addWorkerService(
        task_definition,
        group_id,
        master_service_name,
        desired_count
      )
      .then(
        (data) => {
          dispatch(success(data));
          dispatch(getServices());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(getServices());
        }
      );
  };

  function request() {
    return { type: userConstants.ADD_SERVICE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ADD_SERVICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ADD_SERVICE_FAILURE, error };
  }
}

function removeService(task_definition, group_id) {
  return (dispatch) => {
    dispatch(request());

    userService.removeService(task_definition, group_id).then(
      (data) => {
        dispatch(success(data));
        dispatch(getServices());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(getServices());
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_SERVICE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.DELETE_SERVICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.DELETE_SERVICE_FAILURE, error };
  }
}

function removeAllServices() {
  return (dispatch) => {
    dispatch(request());

    userService.removeAllServices().then(
      (data) => {
        dispatch(success(data));
        dispatch(getServices());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(getServices());
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_ALL_SERVICES_REQUEST };
  }
  function success(data) {
    return { type: userConstants.DELETE_ALL_SERVICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.DELETE_ALL_SERVICES_FAILURE, error };
  }
}

function mocklmsGet() {
  return (dispatch) => {
    dispatch(request());

    userService.mocklmsGet().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.MOCKLMS_GET_REQUEST };
  }
  function success(data) {
    return { type: userConstants.MOCKLMS_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.MOCKLMS_GET_FAILURE, error };
  }
}

function mocklmsCreate(message) {
  return (dispatch) => {
    dispatch(request());

    userService.mocklmsCreate(message).then(
      (data) => {
        dispatch(success(data));
        dispatch(mocklmsGet());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(mocklmsGet());
      }
    );
  };

  function request() {
    return { type: userConstants.MOCKLMS_POST_REQUEST };
  }
  function success(data) {
    return { type: userConstants.MOCKLMS_POST_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.MOCKLMS_POST_FAILURE, error };
  }
}

function mocklmsDelete() {
  return (dispatch) => {
    dispatch(request());

    userService.mocklmsDelete().then(
      (data) => {
        dispatch(success(data));
        dispatch(mocklmsGet());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(mocklmsGet());
      }
    );
  };

  function request() {
    return { type: userConstants.MOCKLMS_DELETE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.MOCKLMS_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.MOCKLMS_DELETE_FAILURE, error };
  }
}

function cronjobsGet() {
  return (dispatch) => {
    dispatch(request());
    userService.cronjobsGet().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.CRONJOBS_GET_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CRONJOBS_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CRONJOBS_GET_FAILURE, error };
  }
}

function cronjobsEnable(cron_name) {
  return (dispatch) => {
    dispatch(request());
    userService.cronjobsEnable(cron_name).then(
      (data) => {
        dispatch(success(data));
        dispatch(cronjobsGet());
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(cronjobsGet());
      }
    );
  };

  function request() {
    return { type: userConstants.CRONJOBS_POST_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CRONJOBS_POST_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CRONJOBS_POST_FAILURE, error };
  }
}

function cronjobsDisable(cron_name) {
  return (dispatch) => {
    dispatch(request());

    userService.cronjobsDisable(cron_name).then(
      (data) => {
        dispatch(success(data));
        dispatch(cronjobsGet());
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(cronjobsGet());
      }
    );
  };

  function request() {
    return { type: userConstants.CRONJOBS_DELETE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CRONJOBS_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CRONJOBS_DELETE_FAILURE, error };
  }
}
