import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { zylocust } from "./zylocust.reducer";
import { alert } from "./alert.reducer";
import { registration } from "./registration.reducer";
import { mocklms } from "./mocklms.reducer";
import { cronjobs } from "./cronjobs.reducer";

const rootReducer = combineReducers({
  authentication,
  zylocust,
  alert,
  registration,
  mocklms,
  cronjobs
});

export default rootReducer;
