import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { DataGrid } from "@material-ui/data-grid";

import { userActions } from "../../_actions";

class MockLMS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.mocklmsGet());
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { message } = this.state;
    const { dispatch } = this.props;
    if (message) {
      dispatch(userActions.mocklmsCreate(message));
    }
  }

  handleDelete(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(userActions.mocklmsDelete());
  }

  handleRefresh(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(userActions.mocklmsGet());
  }

  render() {
    const { authentication, alert, zylocust, mocklms } = this.props;
    const { submitted } = this.state;

    const columns = [
      { field: "timestamp", headerName: "timestamp", width: 200 },
      { field: "data", headerName: "data", width: 400 }
    ];

    const rows = mocklms.posts ? mocklms.posts : [];
    const count = rows.length;

    return (
      <Container component="main" xs={12} sm={12} md={12}>
        <CssBaseline />

        {alert.message && (
          <Alert
            fullWidth={true}
            width={"100%"}
            severity={
              alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"
            }
          >
            <AlertTitle>
              {alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"}
            </AlertTitle>
            {alert.message}
          </Alert>
        )}

        <Typography component="p" variant="body1">
          Mock LMS is a simple PostBin service for testing and logging webhooks
          (HTTP POST requests).
        </Typography>
        <Typography component="p" variant="body1">
          Logs collected have a TTL of 30 minutes.
        </Typography>

        <br />

        <form name="form" onSubmit={this.handleSubmit}>
          <GridContainer display="flex" alignItems="center">
            <GridItem>
              <TextField
                variant="outlined"
                margin="normal"
                required
                id="message"
                label="Create Post"
                placeholder="Message"
                name="message"
                onChange={this.handleChange}
                disabled={mocklms.loading}
              />
            </GridItem>
            <GridItem>
              <Button
                type="submit"
                variant="contained"
                color="default"
                disabled={mocklms.loading}
              >
                Post
              </Button>
            </GridItem>
            <GridItem></GridItem>
            <GridItem>
              <Typography component="p" variant="body1">
                https://zylocust-api.zypoc.com/v1/mocklms
              </Typography>
              <Typography component="p" variant="body1">
                &#123;
                <br />
                &emsp;&emsp;&emsp;"message" : "{this.state.message}"<br />
                &#125;
              </Typography>
            </GridItem>
          </GridContainer>
        </form>

        <br />
        <hr />
        <br />

        <GridContainer display="flex" alignItems="center">
          <GridItem>Mock LMS ({count})</GridItem>
          <GridItem>
            <Button
              variant="contained"
              color="default"
              disabled={mocklms.loading}
              onClick={this.handleRefresh}
            >
              Refresh
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant="contained"
              color="secondary"
              disabled={mocklms.loading}
              onClick={this.handleDelete}
            >
              Delete All
            </Button>
          </GridItem>
        </GridContainer>

        <br />

        <div style={{ height: 640, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            onSelectionChange={this.handleSelectionChange}
          />
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert, zylocust, mocklms } = state;
  return {
    authentication,
    alert,
    zylocust,
    mocklms
  };
}

const connectedMockLMS = connect(mapStateToProps)(MockLMS);
export { connectedMockLMS as default };
