import { registrationConstants } from "../_constants";
import { registrationService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const registrationActions = {
  passwordForgot,
  passwordReset,
  signup,
  signupResend,
  signupConfirm
};

function passwordForgot(email) {
  return (dispatch) => {
    dispatch(request({ email }));
    registrationService.passwordForgot(email).then(
      (user) => {
        dispatch(success(user));
        history.push("/password/reset");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: registrationConstants.PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: registrationConstants.PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: registrationConstants.PASSWORD_FAILURE, error };
  }
}

function passwordReset(email, password, code) {
  return (dispatch) => {
    dispatch(request({ email }));
    registrationService.passwordReset(email, password, code).then(
      (user) => {
        dispatch(success(user));
        history.push("/password/success");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: registrationConstants.PASSWORD_RESET_REQUEST, user };
  }
  function success(user) {
    return { type: registrationConstants.PASSWORD_RESET_SUCCESS, user };
  }
  function failure(error) {
    return { type: registrationConstants.PASSWORD_RESET_FAILURE, error };
  }
}

function signup(email, given_name, family_name, password) {
  return (dispatch) => {
    dispatch(request({ email }));
    dispatch(alertActions.clear());
    registrationService.signup(email, given_name, family_name, password).then(
      (user) => {
        dispatch(success(user));
        history.push("/signup/confirm");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: registrationConstants.SIGNUP_REQUEST, user };
  }
  function success(user) {
    return { type: registrationConstants.SIGNUP_SUCCESS, user };
  }
  function failure(error) {
    return { type: registrationConstants.SIGNUP_FAILURE, error };
  }
}

function signupResend(email) {
  return (dispatch) => {
    dispatch(request({ email }));
    dispatch(alertActions.clear());
    registrationService.signupResend(email).then(
      (user) => {
        dispatch(success(user));
        history.push("/signup/confirm");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: registrationConstants.SIGNUP_RESEND_REQUEST, user };
  }
  function success(user) {
    return { type: registrationConstants.SIGNUP_RESEND_SUCCESS, user };
  }
  function failure(error) {
    return { type: registrationConstants.SIGNUP_RESEND_FAILURE, error };
  }
}

function signupConfirm(email, code) {
  return (dispatch) => {
    dispatch(request({ email }));
    dispatch(alertActions.clear());
    registrationService.signupConfirm(email, code).then(
      (user) => {
        dispatch(success(user));
        history.push("/signup/success");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: registrationConstants.SIGNUP_CONFIRM_REQUEST, user };
  }
  function success(user) {
    return { type: registrationConstants.SIGNUP_CONFIRM_SUCCESS, user };
  }
  function failure(error) {
    return { type: registrationConstants.SIGNUP_CONFIRM_FAILURE, error };
  }
}
