import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import Icon from "@material-ui/core/Icon";
import CardIcon from "components/Card/CardIcon.js";
import Danger from "components/Typography/Danger.js";
import Warning from "@material-ui/icons/Warning";
import Schedule from "@material-ui/icons/Schedule";
import RestoreFromTrash from "@material-ui/icons/RestoreFromTrash";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import WebAssetIcon from "@material-ui/icons/WebAsset";

// @material-ui/icons
import CloudDone from "@material-ui/icons/CloudDone";
import Update from "@material-ui/icons/Update";
import AlarmOn from "@material-ui/icons/AlarmOn";
import CloudOff from "@material-ui/icons/CloudOff";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import DeleteForever from "@material-ui/icons/DeleteForever";
import ChartistGraph from "react-chartist";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import Tasks from "components/Tasks/Tasks.js";
import Code from "@material-ui/icons/Code";
import { bugs, website, server } from "variables/general.js";

import { userActions } from "../../_actions";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(userActions.getServices());
    this.props.dispatch(userActions.mocklmsGet());
    this.props.dispatch(userActions.cronjobsGet());
  }

  render() {
    const { authentication, alert, zylocust, mocklms, cronjobs } = this.props;
    const { group_id, worker_num, submitted } = this.state;

    const tableHead2 = ["serviceName", "rolloutState", "public_ip", "url"];
    const tableData2 = zylocust.services
      ? zylocust.services.map((n) => [
          n.serviceName,
          n.rolloutState,
          n.public_ip,
          n.url
        ])
      : [];

    const masters = (zylocust.services
      ? zylocust.services.map((n) => (n ? n.running : 0))
      : []
    ).reduce((a, b) => a + b, 0);
    const masters_desired = (zylocust.services
      ? zylocust.services.map((n) => (n ? n.desired : 0))
      : []
    ).reduce((a, b) => a + b, 0);

    const workers = (zylocust.services
      ? zylocust.services.map((n) => (n.worker ? n.worker.running : 0))
      : []
    ).reduce((a, b) => a + b, 0);
    const workers_desired = (zylocust.services
      ? zylocust.services.map((n) => (n.worker ? n.worker.desired : 0))
      : []
    ).reduce((a, b) => a + b, 0);

    const cron_jobs = (cronjobs.jobs
      ? cronjobs.jobs.map((n) => (n.State == "ENABLED" ? 1 : 0))
      : []
    ).reduce((a, b) => a + b, 0);
    const cron_jobs_desired = cronjobs.jobs ? cronjobs.jobs.length : 0;

    const tableHead = ["timestamp", "data"];
    const tableData = mocklms.posts
      ? mocklms.posts.map((n) => [n.timestamp, n.data])
      : [];

    const lmsPosts = tableData.length;
    const last_lms_date = lmsPosts > 0 ? mocklms.posts[0].timestamp : "";

    return (
      <Container component="main" xs={12} sm={12} md={12}>
        <CssBaseline />

        {alert.message && (
          <Alert
            fullWidth={true}
            width={"100%"}
            severity={
              alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"
            }
          >
            <AlertTitle>
              {alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"}
            </AlertTitle>
            {alert.message}
          </Alert>
        )}

        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon
                  color={
                    masters_desired == 0
                      ? "primary"
                      : masters == masters_desired && masters_desired > 0
                      ? "success"
                      : "warning"
                  }
                >
                  <WebAssetIcon />
                </CardIcon>
                <Typography component="p" variant="body2" color="textPrimary">
                  Masters
                </Typography>
                <Typography component="p" variant="body1" color="textPrimary">
                  {masters}/{masters_desired}
                </Typography>
              </CardHeader>
              <CardFooter stats>
                {masters != masters_desired && (
                  <div>
                    <Warning /> Spawning...
                  </div>
                )}
                {masters == masters_desired && (
                  <div>
                    {masters_desired > 0 && (
                      <div>
                        <CloudDone /> <span>Ready</span>
                      </div>
                    )}
                    {masters_desired == 0 && (
                      <div>
                        <CloudOff /> <span>Offline</span>
                      </div>
                    )}
                  </div>
                )}
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon
                  color={
                    workers_desired == 0
                      ? "primary"
                      : workers == workers_desired && workers_desired > 0
                      ? "success"
                      : "warning"
                  }
                >
                  <DynamicFeedIcon />
                </CardIcon>
                <Typography component="p" variant="body2" color="textPrimary">
                  Workers
                </Typography>
                <Typography component="p" variant="body1" color="textPrimary">
                  {workers}/{workers_desired}
                </Typography>
              </CardHeader>
              <CardFooter stats>
                {workers != workers_desired && (
                  <div>
                    <Warning /> Spawning...
                  </div>
                )}
                {workers == workers_desired && (
                  <div>
                    {workers_desired > 0 && (
                      <div>
                        <CloudDone /> <span>Ready</span>
                      </div>
                    )}
                    {workers_desired == 0 && (
                      <div>
                        <CloudOff /> <span>Offline</span>
                      </div>
                    )}
                  </div>
                )}
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color={lmsPosts == 0 ? "primary" : "success"}>
                  <RestoreFromTrash />
                </CardIcon>
                <Typography component="p" variant="body2" color="textPrimary">
                  Mock LMS
                </Typography>
                <Typography component="p" variant="body1" color="textPrimary">
                  {lmsPosts}
                </Typography>
              </CardHeader>
              <CardFooter stats>
                {lmsPosts == 0 && (
                  <div>
                    <DeleteForever /> No Posts
                  </div>
                )}
                {lmsPosts > 0 && (
                  <div>
                    <Update /> Last Post on {last_lms_date}
                  </div>
                )}
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon
                  color={
                    cron_jobs == 0
                      ? "primary"
                      : cron_jobs == cron_jobs_desired
                      ? "success"
                      : "warning"
                  }
                >
                  <Schedule />
                </CardIcon>
                <Typography component="p" variant="body2" color="textPrimary">
                  Cron Jobs
                </Typography>
                <Typography component="p" variant="body1" color="textPrimary">
                  {cron_jobs}/{cron_jobs_desired}
                </Typography>
              </CardHeader>
              <CardFooter stats>
                {cron_jobs != cron_jobs_desired && (
                  <div>
                    {cron_jobs > 0 && (
                      <div>
                        <Warning /> <span>{cron_jobs} Job(s) Running</span>
                      </div>
                    )}
                    {cron_jobs == 0 && (
                      <div>
                        <NotificationsOffIcon /> <span>No Jobs Running</span>
                      </div>
                    )}
                  </div>
                )}

                {cron_jobs == cron_jobs_desired && (
                  <div>
                    {cron_jobs > 0 && (
                      <div>
                        <AlarmOn /> <span>All Jobs Running</span>
                      </div>
                    )}
                    {cron_jobs == 0 && (
                      <div>
                        <NotificationsOffIcon /> <span>No Jobs Running</span>
                      </div>
                    )}
                  </div>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader
                color={
                  masters == masters_desired &&
                  workers == workers_desired &&
                  masters > 0 &&
                  workers > 0
                    ? "success"
                    : "primary"
                }
              >
                Swarms ({masters})
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor={
                    masters == masters_desired &&
                    workers == workers_desired &&
                    masters > 0 &&
                    workers > 0
                      ? "success"
                      : "primary"
                  }
                  tableHead={tableHead2}
                  tableData={tableData2}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color={lmsPosts == 0 ? "primary" : "success"}>
                Mock LMS ({lmsPosts})
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor={lmsPosts == 0 ? "primary" : "success"}
                  tableHead={tableHead}
                  tableData={tableData}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert, zylocust, mocklms, cronjobs } = state;
  return {
    authentication,
    alert,
    zylocust,
    mocklms,
    cronjobs
  };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as default };
