import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "../_helpers";
import { alertActions } from "../_actions";
import { PrivateRoute } from "../_components";

import Admin from "layouts/Admin.js";
import Public from "layouts/Public.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      //dispatch(alertActions.clear());
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/" component={Public} />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
