export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "USERS_LOGOUT_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  ADD_SERVICE_REQUEST: "USERS_ADD_SERVICE_REQUEST",
  ADD_SERVICE_SUCCESS: "USERS_ADD_SERVICE_SUCCESS",
  ADD_SERVICE_FAILURE: "USERS_ADD_SERVICE_FAILURE",

  DELETE_SERVICE_REQUEST: "USERS_DELETE_SERVICE_REQUEST",
  DELETE_SERVICE_SUCCESS: "USERS_DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_FAILURE: "USERS_DELETE_SERVICE_FAILURE",

  DELETE_ALL_SERVICES_REQUEST: "USERS_DELETE_ALL_SERVICES_REQUEST",
  DELETE_ALL_SERVICES_SUCCESS: "USERS_DELETE_ALL_SERVICES_SUCCESS",
  DELETE_ALL_SERVICES_FAILURE: "USERS_DELETE_ALL_SERVICES_FAILURE",

  MOCKLMS_GET_REQUEST: "USERS_MOCKLMS_GET_REQUEST",
  MOCKLMS_GET_SUCCESS: "USERS_MOCKLMS_GET_SUCCESS",
  MOCKLMS_GET_FAILURE: "USERS_MOCKLMS_GET_FAILURE",

  MOCKLMS_POST_REQUEST: "USERS_MOCKLMS_POST_REQUEST",
  MOCKLMS_POST_SUCCESS: "USERS_MOCKLMS_POST_SUCCESS",
  MOCKLMS_POST_FAILURE: "USERS_MOCKLMS_POST_FAILURE",

  MOCKLMS_DELETE_REQUEST: "USERS_MOCKLMS_DELETE_REQUEST",
  MOCKLMS_DELETE_SUCCESS: "USERS_MOCKLMS_DELETE_SUCCESS",
  MOCKLMS_DELETE_FAILURE: "USERS_MOCKLMS_DELETE_FAILURE",

  CRONJOBS_GET_REQUEST: "USERS_CRONJOBS_GET_REQUEST",
  CRONJOBS_GET_SUCCESS: "USERS_CRONJOBS_GET_SUCCESS",
  CRONJOBS_GET_FAILURE: "USERS_CRONJOBS_GET_FAILURE",

  CRONJOBS_POST_REQUEST: "USERS_CRONJOBS_POST_REQUEST",
  CRONJOBS_POST_SUCCESS: "USERS_CRONJOBS_POST_SUCCESS",
  CRONJOBS_POST_FAILURE: "USERS_CRONJOBS_POST_FAILURE",

  CRONJOBS_DELETE_REQUEST: "USERS_CRONJOBS_DELETE_REQUEST",
  CRONJOBS_DELETE_SUCCESS: "USERS_CRONJOBS_DELETE_SUCCESS",
  CRONJOBS_DELETE_FAILURE: "USERS_CRONJOBS_DELETE_FAILURE"
};
