import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import Icon from "@material-ui/core/Icon";
import CardIcon from "components/Card/CardIcon.js";
import Danger from "components/Typography/Danger.js";
import Warning from "@material-ui/icons/Warning";
import Store from "@material-ui/icons/Store";
import Cloud from "@material-ui/icons/Cloud";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import WebAssetIcon from "@material-ui/icons/WebAsset";

import { DataGrid } from "@material-ui/data-grid";

import { userActions } from "../../_actions";

class Services extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_services: [],
      submitted: false
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleDeleteService = this.handleDeleteService.bind(this);
    this.handleDeleteSelectedServices = this.handleDeleteSelectedServices.bind(
      this
    );
    this.handleDeleteAllServices = this.handleDeleteAllServices.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getServices());
  }

  handleRefresh(e) {
    this.props.dispatch(userActions.getServices());
  }

  handleSelectionChange(selections) {
    this.setState({ selected_services: selections.rowIds });
  }

  handleDeleteService(service_name) {
    var task_definition = "zylocust-master";
    if (service_name.startsWith("zylocust-worker")) {
      var task_definition = "zylocust-worker";
    }
    let group_id = service_name.replace(task_definition + "-", "");
    this.props.dispatch(userActions.removeService(task_definition, group_id));
    this.setState({ selected_services: [] });
  }

  handleDeleteSelectedServices() {
    let selected_services = this.state.selected_services;
    if (selected_services.length > 0) {
      let selected_service = selected_services[0];
      var task_definition = "zylocust-master";
      if (selected_service.startsWith("zylocust-worker")) {
        var task_definition = "zylocust-worker";
      }
      let group_id = selected_service.replace(task_definition + "-", "");
      this.props.dispatch(userActions.removeService(task_definition, group_id));
    }
    this.setState({ selected_services: [] });
  }

  handleDeleteAllServices() {
    this.props.dispatch(userActions.removeAllServices());
  }

  render() {
    const { authentication, alert, zylocust, mocklms } = this.props;
    const { submitted } = this.state;

    const columns = [
      { field: "serviceName", headerName: "serviceName", width: 250 },
      { field: "rolloutState", headerName: "rolloutState", width: 150 },
      { field: "public_ip", headerName: "public_ip", width: 150 },
      {
        field: "running",
        headerName: "running",
        type: "number",
        width: 120
      },
      {
        field: "desired",
        headerName: "desired",
        type: "number",
        width: 120
      },
      {
        field: "pending",
        headerName: "pending",
        type: "number",
        width: 120
      },
      { field: "url", headerName: "url", width: 250 }
    ];

    const rows = zylocust.services_raw ? zylocust.services_raw : [];
    const count = rows.length;

    return (
      <Container component="main" xs={12} sm={12} md={12}>
        <CssBaseline />

        {alert.message && (
          <Alert
            fullWidth={true}
            width={"100%"}
            severity={
              alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"
            }
          >
            <AlertTitle>
              {alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"}
            </AlertTitle>
            {alert.message}
          </Alert>
        )}

        <GridContainer display="flex" alignItems="center">
          <GridItem>Services ({count})</GridItem>
          <GridItem>
            <Button
              variant="contained"
              color="secondary"
              disabled={
                this.state.selected_services.length == 0 || zylocust.loading
              }
              onClick={this.handleDeleteSelectedServices}
            >
              Delete Selected
            </Button>
          </GridItem>
        </GridContainer>

        <br />

        <div style={{ height: 640, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            onSelectionChange={this.handleSelectionChange}
          />
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert, zylocust } = state;
  return {
    authentication,
    alert,
    zylocust
  };
}

const connectedServices = connect(mapStateToProps)(Services);
export { connectedServices as default };
