import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import Icon from "@material-ui/core/Icon";
import CardIcon from "components/Card/CardIcon.js";
import Danger from "components/Typography/Danger.js";
import Warning from "@material-ui/icons/Warning";
import Store from "@material-ui/icons/Store";
import Cloud from "@material-ui/icons/Cloud";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import WebAssetIcon from "@material-ui/icons/WebAsset";

import { userActions } from "../../_actions";

class Swarms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group_id: "",
      worker_num: 10,
      submitted: false
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleNumChange = this.handleNumChange.bind(this);
    this.handleGroupSubmit = this.handleGroupSubmit.bind(this);

    // This binding is necessary to make `this` work in the callback
    this.handleAddService = this.handleAddService.bind(this);
    this.handleAddWorkerService = this.handleAddWorkerService.bind(this);
    this.handleAddMasterService = this.handleAddMasterService.bind(this);
    this.handleDeleteService = this.handleDeleteService.bind(this);
    this.handleDeleteAllServices = this.handleDeleteAllServices.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getServices());
  }

  handleRefresh(e) {
    this.props.dispatch(userActions.getServices());
  }

  handleGroupChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleNumChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: Number(value) });
  }

  handleGroupSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { group_id } = this.state;
    const { dispatch } = this.props;
    if (group_id) {
      let task_definition = "zylocust-master";
      dispatch(userActions.addMasterService(task_definition, group_id));
    }
  }

  handleAddService(service_name) {
    let tmpAry = service_name.split("-");
    let task_definition = tmpAry[0] + "-" + tmpAry[1];
    let group_id = tmpAry[1];
    this.props.dispatch(userActions.addService(task_definition, group_id));
  }

  handleAddMasterService(group_id) {
    let task_definition = "zylocust-master";
    this.props.dispatch(
      userActions.addMasterService(task_definition, group_id)
    );
  }

  handleAddWorkerService(service_name) {
    let tmpAry = service_name.split("-");
    let task_definition = "zylocust-worker";
    let group_id = tmpAry[2];
    const { worker_num } = this.state;
    if (group_id && worker_num > 0) {
      this.props.dispatch(
        userActions.addWorkerService(
          task_definition,
          group_id,
          service_name,
          worker_num
        )
      );
    }
  }

  handleDeleteService(service_name) {
    let tmpAry = service_name.split("-");
    let task_definition = tmpAry[0] + "-" + tmpAry[1];
    let group_id = tmpAry[2];
    this.props.dispatch(userActions.removeService(task_definition, group_id));
  }

  handleDeleteAllServices() {
    this.props.dispatch(userActions.removeAllServices());
  }

  render() {
    const { authentication, alert, zylocust, mocklms } = this.props;
    const { group_id, worker_num, submitted } = this.state;

    //const tableHead = ["serviceName", "rolloutState", "public_ip", "url"];
    //const tableData = zylocust.services ? zylocust.services.map((n) => [n.serviceName,n.rolloutState,n.public_ip, n.rolloutState == 'COMPLETED' ? n.url : '']) : [];
    const services = zylocust.services ? zylocust.services : [];
    const count = services.length;

    return (
      <Container component="main" xs={12} sm={12} md={12}>
        <CssBaseline />

        {alert.message && (
          <Alert
            fullWidth={true}
            width={"100%"}
            severity={
              alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"
            }
          >
            <AlertTitle>
              {alert.type == "alert-success"
                ? "success"
                : alert.type == "alert-danger"
                ? "error"
                : "info"}
            </AlertTitle>
            {alert.message}
          </Alert>
        )}

        <form name="form" onSubmit={this.handleGroupSubmit}>
          <GridContainer display="flex" alignItems="center">
            <GridItem>
              <TextField
                variant="outlined"
                margin="normal"
                required
                id="group_id"
                label="Create Swarm"
                placeholder="Swarm Name"
                name="group_id"
                onChange={this.handleGroupChange}
                disabled={zylocust.loading}
              />
            </GridItem>
            <GridItem>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={zylocust.loading}
              >
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </form>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <GridContainer display="flex" alignItems="center">
                  <GridItem>Swarms ({count})</GridItem>
                  <GridItem>
                    <Button
                      variant="contained"
                      color="default"
                      disabled={zylocust.loading}
                      onClick={this.handleRefresh}
                    >
                      Refresh
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          {services.map((service) => (
            <GridItem xs={12} sm={6} md={4} key={service.serviceName}>
              <Card>
                <CardHeader stats icon>
                  <CardIcon
                    color={
                      service.worker
                        ? service.worker.rolloutState != "COMPLETED"
                          ? "warning"
                          : "success"
                        : service.rolloutState != "COMPLETED"
                        ? "primary"
                        : "warning"
                    }
                  >
                    {!service.worker && <WebAssetIcon />}
                    {service.worker && <DynamicFeedIcon />}
                  </CardIcon>
                  <Typography component="p" variant="body1" color="textPrimary">
                    {service.serviceName}
                  </Typography>
                  <Typography component="p" variant="body2" color="textPrimary">
                    Workers
                  </Typography>
                  <Typography component="p" variant="body1" color="textPrimary">
                    {service.worker
                      ? service.worker.running + "/" + service.worker.desired
                      : "0/0"}
                  </Typography>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem>
                      {service.public_ip && !service.worker && (
                        <div>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="worker_num"
                            label="number of workers"
                            name="worker_num"
                            onChange={this.handleNumChange}
                            disabled={zylocust.loading}
                          />
                          <br />
                          <Button
                            color="primary"
                            onClick={this.handleAddWorkerService.bind(
                              this,
                              service.serviceName
                            )}
                          >
                            Add Workers
                          </Button>
                        </div>
                      )}
                    </GridItem>
                    <GridItem>
                      {service.worker && (
                        <Button
                          color="secondary"
                          onClick={this.handleDeleteService.bind(
                            this,
                            service.worker.serviceName
                          )}
                        >
                          Delete Workers
                        </Button>
                      )}
                    </GridItem>
                    <GridItem>
                      {!service.worker && (
                        <Button
                          color="secondary"
                          onClick={this.handleDeleteService.bind(
                            this,
                            service.serviceName
                          )}
                        >
                          Delete Master
                        </Button>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>

                <CardFooter stats>
                  <div>
                    {service.rolloutState == "COMPLETED" && (
                      <a href={service.url} target={"_blank"} rel="noreferrer">
                        {service.url}
                      </a>
                    )}
                    {service.rolloutState != "COMPLETED" && (
                      <Danger>
                        <Warning /> {service.rolloutState}
                      </Danger>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert, zylocust, mocklms } = state;
  return {
    authentication,
    alert,
    zylocust,
    mocklms
  };
}

const connectedSwarms = connect(mapStateToProps)(Swarms);
export { connectedSwarms as default };
