import { userConstants } from "../_constants";

export function mocklms(state = {}, action) {
  switch (action.type) {
    case userConstants.MOCKLMS_GET_REQUEST:
      return {
        loading: true
      };
    case userConstants.MOCKLMS_GET_SUCCESS:
      return {
        posts: action.data
      };
    case userConstants.MOCKLMS_GET_FAILURE:
      return {
        error: action.error
      };

    case userConstants.MOCKLMS_POST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.MOCKLMS_POST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.MOCKLMS_POST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.MOCKLMS_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.MOCKLMS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.MOCKLMS_DELETE_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
