export const registrationConstants = {
  PASSWORD_REQUEST: "USERS_PASSWORD_REQUEST",
  PASSWORD_SUCCESS: "USERS_PASSWORD_SUCCESS",
  PASSWORD_FAILURE: "USERS_PASSWORD_FAILURE",

  PASSWORD_RESET_REQUEST: "USERS_PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_SUCCESS: "USERS_PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "USERS_PASSWORD_RESET_FAILURE",

  SIGNUP_REQUEST: "USERS_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "USERS_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "USERS_SIGNUP_FAILURE",

  SIGNUP_RESEND_REQUEST: "USERS_SIGNUP_RESEND_REQUEST",
  SIGNUP_RESEND_SUCCESS: "USERS_SIGNUP_RESEND_SUCCESS",
  SIGNUP_RESEND_FAILURE: "USERS_SIGNUP_RESEND_FAILURE",

  SIGNUP_CONFIRM_REQUEST: "USERS_SIGNUP_CONFIRM_REQUEST",
  SIGNUP_CONFIRM_SUCCESS: "USERS_SIGNUP_CONFIRM_SUCCESS",
  SIGNUP_CONFIRM_FAILURE: "USERS_SIGNUP_CONFIRM_FAILURE"
};
