import React from "react";
import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { registrationActions } from "../../_actions";
import logo from "assets/img/zylocust/death.png";

import { history } from "../../_helpers";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      code: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResend = this.handleResend.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const email = this.props.registration.user
      ? this.props.registration.user.email
      : "";
    const { password, code } = this.state;
    const { dispatch } = this.props;
    console.log(email);
    if (email && password && code) {
      dispatch(registrationActions.passwordReset(email, password, code));
    }
  }

  handleResend(e) {
    e.preventDefault();
    const email = this.props.registration.user
      ? this.props.registration.user.email
      : "";
    const { dispatch } = this.props;
    console.log(email);
    if (email) {
      dispatch(registrationActions.passwordForgot(email));
    }
  }

  render() {
    const { registration, alert } = this.props;
    const { password, code, submitted } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography component="h3" variant="h3">
            zyLocust
          </Typography>
          <img src={logo} alt="Logo" height={150} />
          <Typography component="p" variant="body2">
            loadtesting at biblical proportions
          </Typography>
          <br />
          {alert.message && (
            <Alert
              fullWidth={true}
              width={"100%"}
              severity={
                alert.type == "alert-success"
                  ? "success"
                  : alert.type == "alert-danger"
                  ? "error"
                  : "info"
              }
            >
              <AlertTitle>
                {alert.type == "alert-success"
                  ? "success"
                  : alert.type == "alert-danger"
                  ? "error"
                  : "info"}
              </AlertTitle>
              {alert.message}
            </Alert>
          )}
          <br />
        </Box>

        <Typography component="h5" variant="h5">
          Reset Password
        </Typography>
        <Typography component="p" variant="body2">
          A verification code was sent. Please check your email and enter the
          code below.
        </Typography>

        <form name="form" onSubmit={this.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            onChange={this.handleChange}
            label="new password"
            type="password"
            id="password"
            disabled={registration.loading}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="code"
            value={code}
            onChange={this.handleChange}
            label="verification code"
            type="code"
            id="code"
            disabled={registration.loading}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={registration.loading}
          >
            Update Password
          </Button>
          <br />
          <Button
            fullWidth
            variant="contained"
            onClick={this.handleResend}
            disabled={registration.loading}
          >
            Resend Code
          </Button>

          <Grid container>
            <Grid item>
              <Button component={Link} to="/signin">
                {"Already have an account? Sign In"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { registration, alert } = state;
  return {
    registration,
    alert
  };
}

const connectedPasswordReset = connect(mapStateToProps)(PasswordReset);
export { connectedPasswordReset as default };
