// @material-ui/icons
import Person from "@material-ui/icons/Person";

import SignIn from "views/SignIn/SignIn.js";
import SignUp from "views/SignUp/SignUp.js";
import SignUpConfirm from "views/SignUp/SignUpConfirm.js";
import SignUpSuccess from "views/SignUp/SignUpSuccess.js";
import passwordForgot from "views/PasswordForgot/PasswordForgot.js";
import PasswordReset from "views/PasswordForgot/PasswordReset.js";
import PasswordSuccess from "views/PasswordForgot/PasswordSuccess.js";

const publicRoutes = [
  {
    path: "signin",
    name: "Sign In",
    icon: Person,
    component: SignIn,
    layout: "/"
  },
  {
    path: "signup/success",
    name: "Sign Up Success",
    icon: Person,
    component: SignUpSuccess,
    layout: "/"
  },
  {
    path: "signup/confirm",
    name: "Sign Up Confirm",
    icon: Person,
    component: SignUpConfirm,
    layout: "/"
  },
  {
    path: "signup",
    name: "Sign Up",
    icon: Person,
    component: SignUp,
    layout: "/"
  },
  {
    path: "password/success",
    name: "Password Success",
    icon: Person,
    component: PasswordSuccess,
    layout: "/"
  },
  {
    path: "password/reset",
    name: "Password Reset",
    icon: Person,
    component: PasswordReset,
    layout: "/"
  },
  {
    path: "password",
    name: "Forgot Password",
    icon: Person,
    component: passwordForgot,
    layout: "/"
  }
];

export default publicRoutes;
