export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.access_token) {
    console.log(user.access_token);
    return {
      Authorization: user.access_token,
      "content-type": "application/json"
    };
  } else {
    return {};
  }
}
