import { userConstants } from "../_constants";

export function zylocust(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        services: action.data.results,
        services_raw: action.data.raw
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case userConstants.ADD_SERVICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.ADD_SERVICE_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.DELETE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.DELETE_SERVICE_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.DELETE_ALL_SERVICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.DELETE_ALL_SERVICES_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
