// @material-ui/icons
import iconPerson from "@material-ui/icons/Person";
import iconSwarms from "@material-ui/icons/DynamicFeed";
import iconStorage from "@material-ui/icons/Storage";
import iconDashboard from "@material-ui/icons/Dashboard";
import iconServices from "@material-ui/icons/Cloud";
import iconMockLMS from "@material-ui/icons/RestoreFromTrash";
import iconCronJobs from "@material-ui/icons/Schedule";

import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

import DashboardPage from "views/Dashboard/Dashboard.js";
import SwarmsPage from "views/Swarms/Swarms.js";
import MockLMSPage from "views/MockLMS/MockLMS.js";
import ServicesPage from "views/Services/Services.js";
import CronJobsPage from "views/CronJobs/CronJobs.js";

const privateRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: iconDashboard,
    component: DashboardPage,
    layout: "/admin/"
  },
  {
    path: "swarms",
    name: "Manage Swarms",
    icon: iconSwarms,
    component: SwarmsPage,
    layout: "/admin/"
  },
  {
    path: "services",
    name: "Services",
    icon: iconServices,
    component: ServicesPage,
    layout: "/admin/"
  },
  {
    path: "cronjobs",
    name: "Cron Jobs",
    icon: iconCronJobs,
    component: CronJobsPage,
    layout: "/admin/"
  },
  {
    path: "mocklms",
    name: "Mock LMS",
    icon: iconMockLMS,
    component: MockLMSPage,
    layout: "/admin/"
  }
];

export default privateRoutes;
